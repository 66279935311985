<template>
  <div class="page row">
    <div class="col-12 grid-margin">
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table center-aligned-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Credits</th>
                  <th>Limit</th>
                  <th>Role</th>
                  <!-- <th>Groups</th> -->
                  <!-- <th>Status</th> -->
                </tr>
              </thead>
              <tbody v-if="items">
                <tr v-for="item in items" :key="item.contact_id">
                  <td>{{ item.username }}</td>
                  <td>{{ item.credits }}</td>
                  <td>{{ item.async_limit }}</td>
                  <td>{{ item.admin ? "Admin" : "User" }}</td>
                  <td style="width: 60px">
                    <button
                      href="#"
                      class="icon mr-1 text-muted p-2"
                      @click="(showUpdate = true), (currentItem = item)"
                    >
                      <i class="mdi mdi-grease-pencil"></i>
                    </button>
                    <button
                      href="#"
                      class="icon mr-1 text-muted p-2"
                      @click="deleteConfirm(item.username)"
                    >
                      <i class="mdi mdi-delete"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <Update
      :item="currentItem"
      :show="showUpdate"
      v-if="showUpdate"
      @close="showUpdate = false"
      @submit="updateUser($event)"
    />
  </div>
</template>

<script>
import Update from "../components/users/Update";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      items: [],
      showUpdate: false,
      currentItem: null,
    };
  },
  components: {
    Update,
  },
  computed: {
    ...mapGetters(["admin"]),
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.loadItems();
    },
    loadItems() {
      this.$store.commit("updateLoading", true);
      this.$axios
        .get("/admin/users")
        .then((res) => {
          this.items = res.data.users;
          this.$store.commit("updateLoading", false);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    updateUser(item) {
      this.$axios
        .post("/admin/user/update", item)
        .then((res) => {
          this.items = res.data.users;
          this.showUpdate = false;
          this.initData();
          this.$store.commit("updateLoading", false);
          this.$swal.fire(
            `User ${this.currentItem.username} updated!`,
            ``,
            "success"
          );
        })
        .catch((e) => {
          console.log(e);
        });
    },
    deleteConfirm(name) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.value) {
            this.onDelete(name);
          }
        });
    },
    onDelete(name) {
      this.$axios
        .post(`/admin/unregister/${name}`)
        .then(() => {
          this.loadItems();
          this.$swal.fire(
            "Deleted!",
            `User ${name} has been deleted.`,
            "success"
          );
        })
        .catch((err) => {
          this.$swal.fire("Error", err.response.data.error, "error");
        });
    },
  },
};
</script>

<style lang="scss">
.form-check {
  margin-top: 0;
}
.icon {
  border: none;
  background: none;
}
.actions {
  display: flex;
  @media (max-width: 525px) {
    align-items: flex-start !important;
    & > div {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      .btn {
        margin-left: 0 !important;
        margin-bottom: 0.5rem;
      }
    }
  }
}
.page {
  position: relative;
  min-height: 400px;
}
</style>
<template>
  <b-modal
    v-model="modal"
    id="modallg"
    :title="title"
    size="md"
    @ok="submit"
    @close="$emit('close')"
  >
    <b-form-group label="Credits" label-for="input4">
      <b-form-input
        type="text"
        id="input4"
        v-model="user.credits"
      ></b-form-input>
    </b-form-group>
    <b-form-group label="Limit" label-for="input3">
      <b-form-input
        type="text"
        id="input3"
        v-model="user.async_limit"
      ></b-form-input>
    </b-form-group>
  </b-modal>
</template>

<script>

export default {
  data() {
    return {
      user: {
        credits: null,
        async_limit: null,
        user_id: null,
      },
      modal: false,
      isEdit: false,
    };
  },
  props: {
    item: {
      type: Object,
    },
    title: {
      type: String,
    },
    show: {
      type: Boolean,
    },
  },
  mounted() {
    this.modal = this.show;
    if (this.item) {
      this.user.user_id = this.item.id ? this.item.id : 1;
      this.user.credits = this.item.credits ? this.item.credits : 0;
      this.user.async_limit = this.item.async_limit ? this.item.async_limit : null;
    }
  },
  methods: {
    submit() {
      this.$emit("submit", this.user);
    },
  },
};
</script>
<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
</style>

<style>
.modal-footer {
  background: none;
}
.custom-control-label::after,
.custom-control-label::before {
  top: -0.1rem !important;
}
</style>